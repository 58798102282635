import React from 'react';

const Banner = ({body}) => {
  return (
    <div style={bannercontainer}>
      <div className="animated bounceInLeft">{body}</div>
    </div>
  )
}

export default Banner;

const bannercontainer = {
  marginTop:'91px',
  width: '100%',
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  direction: 'rtl',
  backgroundImage: 'linear-gradient(to right, rgb(206, 156, 80) , rgb(186, 146, 85))',
  color: 'white',
  fontSize: '3em', 
}
