import React from "react";
import './ParagraphWithPicture.css';
import { MDBCardImage } from "mdbreact";

const MultipleParagrapshwithPicture = ({ title, body1, body2, body3, body4, body5, body6, body7, body8, body9, body10, image }) => {
  return (
    <div>
<div style={bodycontainer}>
    <div className='widthChanger'>
        <br />
        <br />
        <h3 style={headerstyler}>{title}</h3>
        <ul style={{direction:'rtl'}}>
            {body1 && <li>{body1}</li>}
            {body2 && <li>{body2}</li>}
            {body3 && <li>{body3}</li>}
            {body4 && <li>{body4}</li>}
            {body5 && <li>{body5}</li>}
            {body6 && <li>{body6}</li>}
            {body7 && <li>{body7}</li>}
            {body8 && <li>{body8}</li>}
            {body9 && <li>{body9}</li>}
            {body10 && <li>{body10}</li>}
        </ul>
    </div>
    <MDBCardImage
          className="img-fluid rounded z-depth-2"
          style={{ marginLeft: "50px" }}
          alt="saraya logo"
          src={image}
          waves
        />

</div>
<hr style={{ height: '50px', width: '80%' }} />
</div>
  );
};

export default MultipleParagrapshwithPicture;

const bodycontainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap:'wrap-reverse',
  padding: "40px",
  textAlign: "right",
  fontSize: "1.5rem"
};
const headerstyler = {
  fontSize: "2rem",
  fontWeight: "bold"
};
