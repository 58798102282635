import MarblePicture64 from "./PhotoGallary/MarblePlates/64.jpg";
import MarblePicture65 from "./PhotoGallary/MarblePlates/65.jpg";
import MarblePicture66 from "./PhotoGallary/MarblePlates/66.jpg";
import MarblePicture67 from "./PhotoGallary/MarblePlates/67.jpg";
import MarblePicture68 from "./PhotoGallary/MarblePlates/68.jpg";
import MarblePicture71 from "./PhotoGallary/MarblePlates/71.jpg";
import MarblePicture72 from "./PhotoGallary/MarblePlates/72.jpg";
import MarblePicture73 from "./PhotoGallary/MarblePlates/73.jpg";
import MarblePicture74 from "./PhotoGallary/MarblePlates/74.jpg";
import MarblePicture75 from "./PhotoGallary/MarblePlates/75.jpg";
import MarblePicture76 from "./PhotoGallary/MarblePlates/76.jpg";
import MarblePicture77 from "./PhotoGallary/MarblePlates/77.jpg";
import MarblePicture78 from "./PhotoGallary/MarblePlates/78.jpg";
import MarblePicture79 from "./PhotoGallary/MarblePlates/79.jpg";
import MarblePicture80 from "./PhotoGallary/MarblePlates/80.jpg";
import MarblePicture81 from "./PhotoGallary/MarblePlates/81.jpg";
import MarblePicture82 from "./PhotoGallary/MarblePlates/82.jpg";
import MarblePicture83 from "./PhotoGallary/MarblePlates/83.jpg";
import MarblePicture84 from "./PhotoGallary/MarblePlates/84.jpg";
import MarblePicture86 from "./PhotoGallary/MarblePlates/86.jpg";
import MarblePicture87 from "./PhotoGallary/MarblePlates/87.jpg";
import MarblePicture88 from "./PhotoGallary/MarblePlates/88.jpg";

import TurkeyFlag from "./CountryFlags/Turkey.png";
import IndiaFlag from "./CountryFlags/India.png";
import SpainFlag from "./CountryFlags/Spain.png";
import ItalyFlag from "./CountryFlags/Italy.png";
// import BrazilFlag from "./CountryFlags/Brazil.png";

const MarbleList = [{
    Name:'كوفي رويال',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture64,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'سيلفر سيتي ترافرتينو',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture65,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'كريما نوفا',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture66,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2cm, 3cm',
    Type: 'رخام', 
},
{
    Name:'توندرا جري',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture67,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'ميرون دارك',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture68,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'تورنتو',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: MarblePicture71,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'رخام', 
},
{
    Name:'بريشيا',
    Origin:'إيطاليا',
    CountryFlag: ItalyFlag,
    Image: MarblePicture72,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'بوردر بيج',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture73,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'داينو رويال',
    Origin:'إيطاليا',
    CountryFlag: ItalyFlag,
    Image: MarblePicture74,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'رخام', 
},
{
    Name:'ميرون',
    Origin:'أسبانيا',
    CountryFlag: SpainFlag,
    Image: MarblePicture75,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'جولدن كريم',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture76,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'رخام', 
},
{
    Name:'كرارة',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture77,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'كريما روز',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture78,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'أونيكس كافي',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: MarblePicture79,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'برلاتو',
    Origin:'إيطاليا',
    CountryFlag: ItalyFlag,
    Image: MarblePicture80,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'رخام', 
},
{
    Name:'ترافرتينو لايت تجاري',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture81,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'ترافرتينو سكوباس',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture82,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'ترافرتينو لايت معالج',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture83,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'ترافرتينو لايت درجة أولى',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture84,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'ترافرتينو لايت عكسي',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture86,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'نوتشي ترافرتينو فانتاستك',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture87,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
{
    Name:'نوتشي ترافرتينو',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: MarblePicture88,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'رخام', 
},
]

export default MarbleList;