import React from "react";
import {
  MDBCarouselCaption,
  MDBCarouselItem,
  MDBView,
  MDBMask
} from "mdbreact";
import { Link } from "react-router-dom";

const CarouselComponent = ({
  itemId,
  image,
  imageAlt,
  header,
  paragraph,
  link
}) => {
  return (
    <div>
      <MDBCarouselItem itemId={itemId}>
        <MDBView>
          <img className="d-block w-100" src={image} alt={imageAlt} />
          <MDBMask overlay="black-light" />
        </MDBView>
        <MDBCarouselCaption>
          <Link to={link}>
            <div style={{ color: "white" }}>
              <h3
                className="h3-responsive"
                style={{
                  textAlign: "right",
                  fontSize: "3vw",
                  fontWeight: "bold",
                  textShadow: "3px 3px 0 #000"
                }}
              >
                {header}
              </h3>
              <p
                style={{
                  textAlign: "right",
                  fontSize: "1.5vw",
                  textShadow: "3px 3px 0 #000"
                }}
                className="animated pulse infinite"
              >
                {paragraph}
              </p>
            </div>
          </Link>
        </MDBCarouselCaption>
      </MDBCarouselItem>
    </div>
  );
};

export default CarouselComponent;