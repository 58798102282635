import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { MDBNavLink } from "mdbreact";


const FooterPage = () => {
  return (
    <MDBFooter color="rgba-black-strong" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-right">
        <MDBRow>
          
          <MDBCol style={navbarTabs} md="5">
            <ul style={unorderedList}>
              <li><MDBNavLink to="#" className="title" disabled style={{color:'white',fontWeight:'bold'}}>روابط:</MDBNavLink></li>
              <li><MDBNavLink to="/">الرئيسية</MDBNavLink></li>
              <li><MDBNavLink to="aboutus">عن الشركة</MDBNavLink></li>
              <li><MDBNavLink to="products">منتجاتنا</MDBNavLink></li>
              <li><MDBNavLink to="/news">الأخبار</MDBNavLink></li>
              <li><MDBNavLink to="/contacts">للتواصل</MDBNavLink></li>
            </ul>            
          </MDBCol>
          <MDBCol md="1"></MDBCol>
          <MDBCol md="6">
            <h5 className="title" style={{fontSize:'2rem',fontWeight:'bold'}}>شركة السرايا الحمراء</h5>
            <p style={{fontSize:'1.5rem'}}>
              للرخام والجرانيت
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid style={{fontSize:'1.2rem'}}>
        &copy; <a href="http://www.saraya.net.ly"> saraya.net.ly </a> {new Date().getFullYear()} حقوق النشر محفوظة
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;

const navbarTabs = {
  fontSize: '1.6rem',
  fontFamily: "'Scheherazade', serif",
}

const unorderedList = {
  listStyleType:'none',
  display:'flex',
  flexDirection:'row',
  flexWrap:'wrap',
  justifyContent:'center',
  fontSize:'1.7rem',
  direction:'rtl'
}