import React from 'react';
import Select from 'react-select';
import "./DropDown.css";
import ItemCard from '../ItemCard';
import { MDBAnimation } from "mdbreact";

const options = [
  { id: 1, value: 'All', label: 'الكل' },
  // { id: 2, value: 'Turkey', label: 'تركيا' },
  { id: 3, value: 'India', label: 'الهند' },
  // { id: 4, value: 'Italy', label: 'إيطاليا' },
  // { id: 5, value: 'Spain', label: 'أسبانيا' },
  { id: 6, value: 'Brazil', label: 'البرازيل' },
];

const GranitePlatesComponent = ({ selectedOption,handleChange,List, product }) => {

  return (
    <div>
      <div className="dropdownBox">
      <h1 className="tc" style={{marginBottom:'30px',fontWeight:'bold'}}>اختر بلد المنشأ</h1>
        <MDBAnimation type="bounce" infinite>
          <h3 className="tc">{product}</h3>
        </MDBAnimation>
        <Select
          id="selectItem"
          value={selectedOption}
          onChange={handleChange}
          options={options}
          placeholder="Select Origin Country"
        />
      </div>

      <div className = 'tc'>
        {
        List.map((user,i)=> {
            return ( 
                <ItemCard 
                  key = {i}
                  name = {List[i].Name}
                  origin = {List[i].Origin}
                  flag = {List[i].CountryFlag}
                  image = {List[i].Image}
                  size = {List[i].Size}
                  cut = {List[i].Cut}
                  thickness = {List[i].Thickness}
                  type = {List[i].Type}
                />
            );    
        })
    }
      </div>
    </div>
  )
}

export default GranitePlatesComponent;