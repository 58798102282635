import React from "react";
import NavbarPage from "../../components/navbar";
import Banner from "../../components/Banner";
import ParagraphWithGallery from "./ParagraphWithGallery";
import Footer from "../../components/footer";



const Work = () => {
    return (
        <div>
            <NavbarPage
                work={true}
            />
            <Banner body="أعمالنا" />
            <ParagraphWithGallery />
            <Footer />
        </div>
    )
}

export default Work;