import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  // MDBDropdown,
  // MDBDropdownToggle,
  // MDBDropdownMenu,
  // MDBDropdownItem
} from "mdbreact";
import FacebookLogo from "./facebook-icon.png";
import NavLogo from "./saraya-logo.png";

class NavbarPage extends Component {
  state = {
    isOpen: false
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { home, aboutus, products, news, contacts, work } = this.props;
    return (
      <MDBNavbar
        color="rgba-black-strong"
        dark
        expand="md"
        fixed="top"
        className="hoverable"
      >
        <MDBNavbarBrand>
          <img
            alt="facebook logo"
            src={NavLogo}
            style={{ marginRight: "2em" }}
          />
          <a href="https://www.facebook.com/sarayamarble/">
            <img alt="facebook logo" src={FacebookLogo} />
          </a>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse
          id="navbarCollapse3"
          isOpen={this.state.isOpen}
          navbar
          style={{ direction: "rtl", textAlign: "right" }}
        >
          <MDBNavbarNav right>
            <MDBNavItem active={home}>
              <MDBNavLink style={navbarTabs} to="/">
                الرئيسية
              </MDBNavLink>
            </MDBNavItem>

            <MDBNavItem active={aboutus}>
              <MDBNavLink style={navbarTabs} to="/aboutus">
                عن الشركة
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={products}>
              <MDBNavLink style={navbarTabs} to="/products">
              منتجاتنا
              </MDBNavLink>
            </MDBNavItem>
           
            <MDBNavItem active={news}>
              <MDBNavLink style={navbarTabs} to="/news">
              الأخبار
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={work}>
              <MDBNavLink style={navbarTabs} to="/work">
              أعمالنا
              </MDBNavLink>
            </MDBNavItem>
            
            {/* <MDBNavItem active={news}>
              <MDBDropdown>
                <MDBDropdownToggle caret nav>
                  <div className="d-none d-md-inline" style={navbarTabs}>
                    الأخبار
                  </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default">
                  <MDBDropdownItem>
                    <MDBNavLink style={DropBarTabs} to="/news">
                      أخبارنا
                    </MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBNavLink style={DropBarTabs} to="/work">
                      أعمالنا
                    </MDBNavLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem> */}

            <MDBNavItem active={contacts}>
              <MDBNavLink style={navbarTabs} to="/contacts">
                تواصل معنا
              </MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default NavbarPage;

const navbarTabs = {
  fontSize: "2rem",
  margin: "0 0.5rem",
  fontFamily: "'Scheherazade', serif"
};

// const DropBarTabs = {
//   fontSize: "2rem",
//   margin: "0 0.5rem",
//   fontFamily: "'Scheherazade', serif",
//   color: "black"
// };