import GranitePicture29 from "./PhotoGallary/GranitePlates/29.jpg";
import GranitePicture31 from "./PhotoGallary/GranitePlates/31.jpg";
import GranitePicture33 from "./PhotoGallary/GranitePlates/33.jpg";
import GranitePicture38 from "./PhotoGallary/GranitePlates/38.jpg";
import GranitePicture39 from "./PhotoGallary/GranitePlates/39.jpg";
import GranitePicture40 from "./PhotoGallary/GranitePlates/40.jpg";
import GranitePicture42 from "./PhotoGallary/GranitePlates/42.jpg";
import GranitePicture44 from "./PhotoGallary/GranitePlates/44.jpg";
import GranitePicture45 from "./PhotoGallary/GranitePlates/45.jpg";
import GranitePicture46 from "./PhotoGallary/GranitePlates/46.jpg";
import GranitePicture47 from "./PhotoGallary/GranitePlates/47.jpg";
import GranitePicture48 from "./PhotoGallary/GranitePlates/48.jpg";
import GranitePicture49 from "./PhotoGallary/GranitePlates/49.jpg";
import GranitePicture50 from "./PhotoGallary/GranitePlates/50.jpg";
import GranitePicture51 from "./PhotoGallary/GranitePlates/51.jpg";
import GranitePicture52 from "./PhotoGallary/GranitePlates/52.jpg";
import GranitePicture53 from "./PhotoGallary/GranitePlates/53.jpg";
import GranitePicture55 from "./PhotoGallary/GranitePlates/55.jpg";
import GranitePicture56 from "./PhotoGallary/GranitePlates/56.jpg";
import GranitePicture57 from "./PhotoGallary/GranitePlates/57.jpg";
import GranitePicture58 from "./PhotoGallary/GranitePlates/58.jpg";
import GranitePicture59 from "./PhotoGallary/GranitePlates/59.jpg";
import GranitePicture60 from "./PhotoGallary/GranitePlates/60.jpg";
import GranitePicture61 from "./PhotoGallary/GranitePlates/61.jpg";
import GranitePicture63 from "./PhotoGallary/GranitePlates/63.jpg";

// import TurkeyFlag from "./CountryFlags/Turkey.png";
import IndiaFlag from "./CountryFlags/India.png";
// import SpainFlag from "./CountryFlags/Spain.png";
// import ItalyFlag from "./CountryFlags/Italy.png";
import BrazilFlag from "./CountryFlags/Brazil.png";

//Filter List 1 => Turkey
//            2 => India
//            3 => Italy
//            4 => Spain
//            5 => Brazil

const GraniteList = [{
    Name:'براديزيو باشا',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture29,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'جولدن براديزيو',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture31,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'جرانيت', 
},
{
    Name:'أسود فيا لكتيا',
    Origin:'البرازيل',
    CountryFlag: BrazilFlag,
    Image: GranitePicture33,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'بلاك فورست',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture38,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'جيالو فيوريتو',
    Origin:'البرازيل',
    CountryFlag: BrazilFlag,
    Image: GranitePicture39,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'جرانيت', 
},
{
    Name:'جلاكسي',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture40,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'جرانيت', 
},
{
    Name:'تشيري براون',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture42,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm',
    Type: 'جرانيت', 
},
{
    Name:'كريستال يلو',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture44,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'جرانيت', 
},
{
    Name:'نيو كوبر',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture45,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm',
    Type: 'جرانيت', 
},
{
    Name:'أكوا فيردي',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture46,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'ملتي كلر',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture47,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm, 3 cm',
    Type: 'جرانيت', 
},
{
    Name:'سيليكا رستك',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture48,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'كشمير جولد',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture49,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm',
    Type: 'جرانيت', 
},
{
    Name:'أوشن بلو',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture50,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '2 cm',
    Type: 'جرانيت', 
},
{
    Name:'براديزيو براون',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture51,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'وايت ريفر',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture52,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'براديزيو كلاسيك',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture53,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'برادا جولد',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture55,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'تان براون ليثر فينش',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture56,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'ريفر جولد',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture57,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'سيسيليا جولد',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture58,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'روز وود',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture59,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'فيسكونت وايت',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture60,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'نيو فينيثيان جولد',
    Origin:'البرازيل',
    CountryFlag: BrazilFlag,
    Image: GranitePicture61,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
{
    Name:'سيليكا جولد',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: GranitePicture63,
    Size: 'حسب الطلب',
    Cut: 'ألواح',
    Thickness: '3 cm',
    Type: 'جرانيت', 
},
]

export default GraniteList;