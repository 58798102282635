import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "./index.css";
import Home from './Pages/Home/Home';
import Products from './Pages/Products/Products';
import AboutUs from './Pages/AboutUs/AboutUs';
import Contacts from './Pages/Contacts/Contacts';
import News from './Pages/News/News';
import Work from './Pages/Work/Work';
import IntroToProducts from './Pages/IntroToProducts/IntroToProducts';

const App = () => {
  return (
    <Router>
        <div>
          <Route exact path='/' component={Home} />
          <Route path='/products' component={Products} />
          <Route path='/news' component={News} />
          <Route path='/work' component={Work} />
          <Route path='/contacts' component={Contacts} />
          <Route path='/aboutus' component={AboutUs} />
          <Route path='/introtoproducts' component={IntroToProducts} />
        </div>
      </Router>
  )
}

export default App;