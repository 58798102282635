import React from "react";
import Banner from "../../components/Banner";
import FooterPage from "../../components/footer";
import NavbarPage from "../../components/navbar";
import ParagraphWithPicture from "../../components/ParagraphWithPicture";
import ParagraphImage from "./ParagraphImage.png";
import MultipleParagrapshwithPicture from "../../components/MultipleParagraphswithPicture";
import marbleImage from "./marbleImage2.png";
import graniteImage from "./graniteImage2.png";

const HomeParagraph1 = {
  title: "شركة السرايا الحمراء للرخام والجرانيت",
  body:
    "هي إحدى الشركات الرائدة في مجال استيراد وقص الرخام والجرانيت، تأسست الشركة سنة 2000م ، حيث كانت الانطلاقة بمقر واحد للشركة ، والآن تمتلك الشركة ثلاثة فروع على مستوى مدينة طرابلس ، حيث تسعى الشركة دائماً إلى تقديم أجود الخامات للرقي بالسوق المحلية وتقديم أفضل الخدمات للزبائن للوصول إلى بيئة أكثر حداثة وتطور على المستوى التجاري والإنساني",
  image: ParagraphImage
};

const HomeParagraph2 = {
  title: "التعريف بالرخام",
  body1:
    "الرخام هو صخر كلسي متحول، يتكون من الكالسيت النقي الذي هو عبارة عن كربونات الكالسيوم. وقد تكون تحت ظروف نادرة من الضغط والحرارة الهائلتين في جوف الأرض. يستعمل في النحت ، وكذلك يستعمل كمادة بنائية لإكساء الأرضيات والجدران والسلالم مما يجعل المباني تتميز بالرقي والفخامة ",
  body2:
    "تشتهر عدة دول في إنتاجه منها ، تركيا، إسبانيا، إيطاليا والتي تعد في المرتبة الأولى.ومما يميزه أيضا تفاعله مع الأحماض وهو ينشأ في البيئات البحرية. واستعمال الرخام قد عرف خلال العصور القديمة التى اشتهرت بالمبانى والقصور الفاخرة المزينة بأعمدة وتماثيل مصنوعة من الرخام",
  body3:
    "و هذا يثبت أن الرخام قد عرف منذ آلاف السنين قبل الميلاد. حيث عرف الفراعنة الرخام في مصر منذ أكثر من 5 ألاف سنة فقد أستخدم في تكسية الأهرامات وفي بناء المعابد وقصور الملوك وتماثيلهم والمسلات وأعمدة المعابد ",
  body4:
    "وكان الرخام وسيلة الفنانين في التعبير سواء في فن المعمار أوالنحت حيث اشتهرت به مبانى اليونان القديمة وتماثيل روما والعديد من المساجد والقصور الفخمة في بلاد الاندلس أيام الحضارة الإسلامية والتي لازال روعة وجمال مبانيها موجودة حتى وقتنا الحاضر وشاهدة على تلك الحضارة المعمارية العظيمة",
  image: marbleImage
};

const HomeParagraph3 = {
  title: "التعريف بالجرانيت",
  body:
    "هو عبارة عن صخر ناري جوفي يتكون تحت درجات حرارة عالية ناتجة عن انصهار الصخور أثناء حدوث البراكين ، يتشكل الجَرَانِيت أساسًا من ثلاثة معادن ـ معدن الكوارتز ومعدن الحديد وسليكات الألومنيوم . وتجعل هذه المعادن الجرانيت أبيضا أو قرمزيًا أو رماديًا فاتحًا. ويحتوي الجرانيت أيضًا على كميات قليلة من المعادن البنية الداكنة أو الخضراء الداكنة أو السوداء . وتتميز حبيبات المعادن في الجرانيت بالضخامة بحيث يمكن التفريق بينها بسهولة وكذلك يتميز الجرانيت بالقسوة والصلابة الشديدة حيث أن البرود البطيء للإنصهار البركاني هو الذي يحدد نسبة المعادن ويتحكم في لون وصلابة الجرانيت. و يوجد حجر الجرانيت بكثرة في المناطق الجبلية والتي تشتهر بكثرة البراكين ومن هذه البلدان الهند والبرازيل ",
  image: graniteImage
};

const IntroToProducts = () => {
  return (
    <div>
      <NavbarPage
        home={false}
        aboutus={false}
        products={true}
        news={false}
        contacts={false}
      />
      <Banner body = "نبذة عن المنتجات" />

      <ParagraphWithPicture
        title={HomeParagraph1.title}
        body={HomeParagraph1.body}
        image={HomeParagraph1.image}
      />

      <MultipleParagrapshwithPicture
        title={HomeParagraph2.title}
        body1={HomeParagraph2.body1}
        body2={HomeParagraph2.body2}
        body3={HomeParagraph2.body3}
        body4={HomeParagraph2.body4}
        image={HomeParagraph2.image}
      />

      <ParagraphWithPicture
        title={HomeParagraph3.title}
        body={HomeParagraph3.body}
        image={HomeParagraph3.image}
      />

      <FooterPage />
    </div>
  );
};

export default IntroToProducts;
