import React from 'react';
import ProductsCard from './ProductsCard';
import MarblePicture from "./marble.png";
import GranitePicture from "./granite.png";
import SquarePicture from "./squares.jpg"

const productsInfo = [
  {
    title: 'ألواح الرخام',
    body: ' هي عبارة عن شرائح كبيرة من الرخام الطبيعي متعددة الأنواع والألوان ويتم قصها حسب مقاسات الزبون',
    image: MarblePicture,
    buttonValue: 'MarblePlates'
  },
  {
    title: 'ألواح الجرانيت',
    body: 'هي عبارة عن شرائح كبيرة من الجرانيت الطبيعي متعددة الأنواع والألوان ويتم قصها حسب مقاسات الزبون',
    image: GranitePicture,
    buttonValue: 'GranitePlates',
  },
  {
  title: 'الترابيع',
  body: 'هي عبارة عن مقاسات جاهزة من الرخام والجرانيت ومن أشهر المقاسات الجاهزة 60×30 و 60×60 وتستخدم في إكساء الأرضيات والواجهات والحوائط',
  image: SquarePicture,
  buttonValue: 'Squares',
}
]


const ProductsMain = ({handleClick}) => {
  return (
    <div>
      <div style={cardsContainer}>
        {
          productsInfo.map((user, i) => {
            return (
              <ProductsCard
                key={i}
                title={productsInfo[i].title}
                body={productsInfo[i].body}
                image={productsInfo[i].image}
                ButtonValue={productsInfo[i].buttonValue}
                onClick = {handleClick}
              />
            );
          })
        }
      </div>
    </div>
  )
}

export default ProductsMain;

const cardsContainer = {
  display: 'inline-flex',
  direction: 'rtl',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  width: '90%',
  margin: 'auto',
}