import React from "react";
import "./ParagraphWithPicture.css";
import { MDBCardImage } from "mdbreact";
import ReadMoreAndLess from 'react-read-more-less';

const ParagraphWithPicture = ({ title, body, image }) => {
  return (
    <div>
      <div style={bodycontainer}>
        <div className="widthChanger">
          <br />
          <br />
          <h3 style={headerstyler}>{title}</h3>
          <ReadMoreAndLess
                className="read-more-content"
                charLimit={100}
                readMoreText="المزيد "
                readLessText=" أقل "
            >
                {body}
            </ReadMoreAndLess>
        </div>
        <MDBCardImage
          className="img-fluid rounded imageMargin"
          alt="saraya logo"
          src={image}
          waves
        />
      </div>
      <hr style={{ height: "50px", width: "80%" }} />
    </div>
  );
};

export default ParagraphWithPicture;

const bodycontainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap-reverse",
  padding: "40px",
  textAlign: "right",
  fontSize: "1.5rem"
};
const headerstyler = {
  fontSize: "2rem",
  fontWeight: "bold"
};