import React from 'react';
import { MDBTable, MDBTableBody } from 'mdbreact';
import "./ItemCard.css";

const RowLabel = {
    ProductName: "اسم الصنف",
    OriginCountry: "بلد المنشأ",
    Cut: "التصنيف",
    Size: "المقاس",
    Type: "الصنف",
    Thickness: "السُّمك",
}

const ItemCard = ({ name, origin, flag, image, size, cut, thickness, type }) => {
    return (
        <div className='tc bg-washed-white dib br3 pa3 ma2 grow bw2 shadow-5'>
            <div className="cardheader">
                <div className="cardname">{name}</div>
                <div className="cardorigin">
                    <img alt='countryflag' src={flag} />
                    <div style= {{marginLeft:'1em'}}>{origin}</div>
                </div>
            </div>
            <img alt='marble' src={image} />
            <div className="tableitems">
                <MDBTable hover>
                    <MDBTableBody>
                        <tr>
                            <td>{cut}</td>
                            <td className="tableheader">{RowLabel.Cut}</td>
                            <td>|</td>
                            <td>{size}</td>
                            <td className="tableheader">{RowLabel.Size}</td>
                        </tr>
                        <tr>
                            <td>{type}</td>
                            <td className="tableheader">{RowLabel.Type}</td>
                            <td>|</td>
                            <td>{thickness}</td>
                            <td className="tableheader">{RowLabel.Thickness}</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </div>
        </div>
    );
}

export default ItemCard;