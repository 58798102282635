import React from "react";
import { Carousel } from "react-responsive-carousel";
import img1 from "./WorkGallery/work1.jpg";
import img2 from "./WorkGallery/work2.jpg";
import img3 from "./WorkGallery/work3.jpg";
import img4 from "./WorkGallery/work4.jpg";
import img5 from "./WorkGallery/work5.jpg";
import img6 from "./WorkGallery/work6.jpg";
import img7 from "./WorkGallery/work7.jpg";
import img8 from "./WorkGallery/work8.jpg";
import img9 from "./WorkGallery/work9.jpg";
import img10 from "./WorkGallery/work10.jpg";
import img11 from "./WorkGallery/work11.jpg";
import img12 from "./WorkGallery/work12.jpg";
import img13 from "./WorkGallery/work13.jpg";
import img14 from "./WorkGallery/work14.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const gallery = [
  {
    image: img1,
    imageAlt: "First",
    // legend: "قوللي"
  },
  {
    image: img2,
    imageAlt: "Second",
    // legend: "شني"
  },
  {
    image: img3,
    imageAlt: "Third",
    // legend: "تبي"
  },
  {
    image: img4,
    imageAlt: "Fourth",
    // legend: "تكتب"
  },
  {
    image: img5,
    imageAlt: "Fifth",
    // legend: "علي"
  },
  {
    image: img6,
    imageAlt: "Sixth",
    // legend: "كل"
  },
  {
    image: img7,
    imageAlt: "Seventh",
    // legend: "صورة"
  },
  {
    image: img8,
    imageAlt: "Eighth",
    // legend: "Legend 8"
  },
  {
    image: img9,
    imageAlt: "Nineth",
    // legend: "Legend 9"
  },
  {
    image: img10,
    imageAlt: "Tenth",
    // legend: "Legend 10"
  },
  {
    image: img11,
    imageAlt: "Eleventh",
    // legend: "Legend 11"
  },
  {
    image: img12,
    imageAlt: "Twelveth",
    // legend: "Legend 12"
  },
  {
    image: img13,
    imageAlt: "Thirteenth",
    // legend: "Legend 13"
  },
  {
    image: img14,
    imageAlt: "Fourteenth",
    // legend: "Legend 14"
  }
];

const ParagraphWithGallery = () => {
  return (
    <div>
      <div style={workheaderstyler}>
        <h4 style={mainheaderstyler}>
          بعض أعمال شركة السرايا للرخام والجرانيت
        </h4>
      </div>

      <div style={GalleryStyler}>
        <Carousel autoPlay swipeable showStatus={false} infiniteLoop={true}>
          {gallery.map((image, i) => {
            return (
              <div>
                <img src={gallery[i].image} alt={gallery[i].imageAlt} />
                {/* <p className="legend">{gallery[i].legend}</p> */}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default ParagraphWithGallery;

const GalleryStyler = {
  maxWidth: "85%",
  margin: "20px auto"
};

const workheaderstyler = {
  textAlign: "center",
  margin: "50px 0"
};

const mainheaderstyler = {
  fontWeight: "bold",
  fontSize: "2.3rem"
};