import React, { Component } from 'react';
import NavbarPage from "../../components/navbar";
import Banner from "../../components/Banner";
import Footer from "../../components/footer";
import MarbleList from "../../storage/MarblePlatesStorage";
import GraniteList from "../../storage/GranitePlatesStorage";
import SquaresList from "../../storage/SquaresStorage";
import ProductsMain from './ProductsMain';
import MarblePlatesComponent from './Search/MarblePlatesComponent';
import GranitePlatesComponent from './Search/GranitePlatesComponent';
import SquaresComponent from './Search/SquaresComponent';

class Products extends Component {
  constructor() {
    super();
    this.state = {
      MarblePlates: [],
      GranitePlates: [],
      Squares: [],
      selectedOption: '',
      buttonValue: '',
      initialOption: '',
    }
  }
  componentDidMount() {
    this.setState({ MarblePlates: MarbleList, GranitePlates: GraniteList, Squares: SquaresList })
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  }

  handleClick = (event) => {
    this.setState({ buttonValue: event.target.value });
  }

  render() {

    const { MarblePlates, GranitePlates, Squares, selectedOption } = this.state;
    const filteredMarblePlates = MarblePlates.filter(MarblePlate => {
      if (selectedOption.value !== 'Turkey' && selectedOption.value !== 'India' && selectedOption.value !== 'Spain' && selectedOption.value !== 'Italy') {
        return MarblePlates;
      } else {
        return MarblePlate.Origin === selectedOption.label;
      }
    })

    const filteredGranitePlates = GranitePlates.filter(GranitePlate => {
      if (selectedOption.value !== 'Turkey' && selectedOption.value !== 'India' && selectedOption.value !== 'Spain' && selectedOption.value !== 'Italy' && selectedOption.value !== 'Brazil') {
        return GranitePlates;
      } else {
        return GranitePlate.Origin === selectedOption.label;
      }
    })
    const filteredSquares = Squares.filter(Square => {
      if (selectedOption.value !== 'Turkey' && selectedOption.value !== 'India' && selectedOption.value !== 'Spain' && selectedOption.value !== 'Italy' && selectedOption.value !== 'Brazil') {
        return Squares;
      } else {
        return Square.Origin === selectedOption.label;
      }
    })

    return (
      <div>
        <NavbarPage
          home={false}
          aboutus={false}
          products={true}
          news={false}
          contacts={false}
        />
        <Banner body="منتجاتنا" />
        {/* <div style={{height:'10rem'}}></div> */}
        <ProductsMain handleClick={this.handleClick} />
        
        {
          (this.state.buttonValue === 'MarblePlates') ?
            <MarblePlatesComponent selectedOption={this.state.selectedOption} handleChange={this.handleChange} List={filteredMarblePlates} product="لألواح الرخام" /> :
            (this.state.buttonValue === 'GranitePlates') ?
              <GranitePlatesComponent selectedOption={this.state.selectedOption} handleChange={this.handleChange} List={filteredGranitePlates} product="لألواح الجرانيت" /> :
              (this.state.buttonValue === 'Squares') ?
                <SquaresComponent selectedOption={this.state.selectedOption} handleChange={this.handleChange} List={filteredSquares} product="للترابيع" /> :
                <p></p>
        }
        
        <Footer />
      </div>
    )
  }
}

export default Products;