import React from "react";
import CarouselComponent from "./carouselComponent";
import { MDBCarousel, MDBCarouselInner } from "mdbreact";
import carouselPicture1 from './carousel-1.jpg';
import carouselPicture2 from './carousel-2.jpg';
import carouselPicture3 from './carousel-3.jpg';

const componentInfo = [
  {
    itemId: 1,
    image: carouselPicture1,
    imageAlt: "First slide",
    header: 'شركة السرايا الحمراء',
    paragraph: 'ترحب بكم شركة السرايا الحمراء للرخام والجرانيت وتتمنى منكم زيارتها في أحد فروعها الثلاثة الكائنة بمنطقة السبعة',
    link: '/',
  },
  {
    itemId: 2,
    image: carouselPicture2,
    imageAlt: "Second slide",
    header: 'آخر أخبار الشركة',
    paragraph: 'تخفيضات السرايا للرخام مستمرة',
    link: '/news',
  },
  {
    itemId: 3,
    image: carouselPicture3,
    imageAlt: "Third slide",
    header: 'منتجات الشركة',
    paragraph: '... تقدم شركة السرايا الحمراء العديد من المنتجات',
    link: '/products',
  }
]

const CarouselPage = () => {
  return (

    <MDBCarousel
      activeItem={1}
      length={componentInfo.length}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      style={{ margin: '91px 0 5vh 0' }}
    >

      <MDBCarouselInner>

      {
        componentInfo.map((info,i) => {
          return (
          <CarouselComponent
          key = {i}
          itemId = {componentInfo[i].itemId}
          image = {componentInfo[i].image}
          imageAlt = {componentInfo[i].imageAlt}
          header = {componentInfo[i].header}
          paragraph = {componentInfo[i].paragraph}
          link = {componentInfo[i].link}
          />
          )
        }
        )
      }
      </MDBCarouselInner>
    </MDBCarousel>
  );
}

export default CarouselPage;