import React from "react";
import NavbarPage from "../../components/navbar";
import Banner from "../../components/Banner";
import Footer from "../../components/footer";
import ContactsComponent from "./ContactsComponent";
import { MDBIcon} from "mdbreact";
import buildingPicture1 from "./saraya-1.jpg";
import buildingPicture2 from "./saraya-2.jpg";
import buildingPicture3 from "./saraya-3.jpg";


const locationInfo = [{
    mainHeader: 'المقر الأول',
    location: 'السبعة - بالقرب من جسر المجد',
    buildingPicture: buildingPicture1,
    phone: '00913714189 – 0918882898',
    mapLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1675.838081234208!2d13.250095033559747!3d32.853824995715264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzLCsDUxJzEzLjgiTiAxM8KwMTUnMDQuMSJF!5e0!3m2!1sen!2sly!4v1558865629532!5m2!1sen!2sly',
},
{
    mainHeader: 'المقر الثاني',
    location: 'السبعة - الإشارة الضوئية بالقرب من وزارة الشباب والرياضة',
    buildingPicture: buildingPicture2,
    phone: '0927049702- 0927637122',
    mapLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4742.344693939073!2d13.261490017166912!3d32.85735983798459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1021fb23369741bb!2z2LTYsdmD2Kkg2KfZhNiz2LHYp9mK2Kcg2KfZhNit2YXYsdin2KEg2YTZhNix2K7Yp9mFINmI2KfZhNis2LHYp9mG2YrYqiwgQWwgU2FyYXlhIEFsaGFtcmEgZm9yIE1hcmJsZSBhbmQgR3Jhbml0ZQ!5e0!3m2!1sen!2sly!4v1557573438801!5m2!1sen!2sly',
},
{
    mainHeader: 'المقر الثالث',
    location: 'السبعة - مقابل مسجد بن قصيعة',
    buildingPicture: buildingPicture3,
    phone: '094000535- 0925292823',
    mapLocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1676.1070588799048!2d13.254876437923459!3d32.839581996184755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzLCsDUwJzIyLjUiTiAxM8KwMTUnMjEuMSJF!5e0!3m2!1sen!2sly!4v1559568219825!5m2!1sen!2sly"
}
]

const Contacts = () => {
    return (
        <div>
            <NavbarPage
                home={false}
                aboutus={false}
                products={false}
                news={false}
                contacts={true}
            />
            <Banner body="للتواصل معنا" />

            <div>
                <div style={contactsheaderstyler}>
                    <h4 style={mainheaderstyler}>عبر وسائل الاتصال المتاحة</h4>
                </div>
                <div style={contactbodystyler}>
                    <div style={contactitemstyler}>
                        <MDBIcon fab icon="facebook-f" size="5x" />
                        <h4 style={contacth4styler}>صفحتنا على الفيسبوك</h4>
                        <a href="https://www.facebook.com/sarayamarble/" style={paragraphstyler}>facebook.com/sarayamarble</a>
                    </div>
                    <div style={contactitemstyler}>
                        <MDBIcon icon="business-time" size="5x" />
                        <h4 style={contacth4styler}>مواعيد العمل</h4>
                        <p style = {paragraphstyler}>السبت إلى الخميس</p>
                        <p style = {paragraphstyler}>9 صباحاً - 5 مساءً</p>
                    </div>
                    <div style={contactitemstyler}>
                        <MDBIcon icon="phone" size="5x" />
                        <h4 style={contacth4styler}>الهاتف</h4>
                        <p style={paragraphstyler}>0214625560</p>
                        <p style={paragraphstyler}>0927049702</p>
                    </div>
                    <div style={contactitemstyler}>
                        <MDBIcon far icon="envelope-open" size="5x" />
                        <h4 style={contacth4styler}>البريد الإلكتروني</h4>
                        <p style={paragraphstyler}>info@saraya.net.ly</p>
                    </div>
                </div>
                <div style={contactsheaderstyler}>
                    <h4 style={mainheaderstyler}>تفضلو بزيارتنا في مقراتنا المتعددة بمدينة طرابلس</h4>
                </div>
            </div>

            {
                locationInfo.map((locations, i) => {
                    return (
                        <ContactsComponent
                            mainHeader={locationInfo[i].mainHeader}
                            location={locationInfo[i].location}
                            buildingPicture={locationInfo[i].buildingPicture}
                            phone={locationInfo[i].phone}
                            mapLocation={locationInfo[i].mapLocation}
                        />
                    )
                })
            }
            <Footer />
        </div>
    )
}

export default Contacts;

const contactbodystyler = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
}

const contactitemstyler = {
    margin: '5% 5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'rtl',
    textAlign: 'center',
    fontSize: '0.8rem',
}

const contactsheaderstyler = {
    textAlign: 'center',
    margin: '50px 0',
}

const contacth4styler = {
    margin: '15px 0',
    fontSize:'2rem',
    fontWeight:'bold',
}

const paragraphstyler = {
    fontSize:'1.4rem',
    direction:'ltr',
}

const mainheaderstyler = {
    fontWeight:'bold',
    fontSize:'2.3rem'
}