import SquarePicture1 from "./PhotoGallary/Squares/1.jpg";
import SquarePicture2 from "./PhotoGallary/Squares/2.jpg";
import SquarePicture3 from "./PhotoGallary/Squares/3.jpg";
import SquarePicture4 from "./PhotoGallary/Squares/4.jpg";
import SquarePicture6 from "./PhotoGallary/Squares/6.jpg";
import SquarePicture7 from "./PhotoGallary/Squares/7.jpg";
import SquarePicture8 from "./PhotoGallary/Squares/8.jpg";
import SquarePicture10 from "./PhotoGallary/Squares/10.jpg";
import SquarePicture12 from "./PhotoGallary/Squares/12.jpg";
import SquarePicture14 from "./PhotoGallary/Squares/14.jpg";
import SquarePicture15 from "./PhotoGallary/Squares/15.jpg";
import SquarePicture16 from "./PhotoGallary/Squares/16.jpg";
import SquarePicture18 from "./PhotoGallary/Squares/18.jpg";
import SquarePicture19 from "./PhotoGallary/Squares/19.jpg";
import SquarePicture21 from "./PhotoGallary/Squares/21.jpg";
import SquarePicture22 from "./PhotoGallary/Squares/22.jpg";
import SquarePicture23 from "./PhotoGallary/Squares/23.jpg";
import SquarePicture25 from "./PhotoGallary/Squares/25.jpg";
import SquarePicture26 from "./PhotoGallary/Squares/26.jpg";
import SquarePicture27 from "./PhotoGallary/Squares/27.jpg";
import SquarePicture28 from "./PhotoGallary/Squares/28.jpg";
import SquarePicture85 from "./PhotoGallary/Squares/85.jpg";
import SquarePicture89 from "./PhotoGallary/Squares/89.jpg";

import TurkeyFlag from "./CountryFlags/Turkey.png";
import IndiaFlag from "./CountryFlags/India.png";
// import SpainFlag from "./CountryFlags/Spain.png";
import ItalyFlag from "./CountryFlags/Italy.png";
// import BrazilFlag from "./CountryFlags/Brazil.png";

//Filter List 1 => Turkey
//            2 => India
//            3 => Italy
//            4 => Spain
//            5 => Brazil

const SquaresList = [{
    Name:'داينو رويال',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture1,
    Size: '60 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'ريتش جري',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture2,
    Size: '60 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'أنطاليا بيج',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture3,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'سيلفر سيتي ترافرتينو',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture4,
    Size: '61 cm x 30.5 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'كوبرا جري',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture6,
    Size: '61 cm x 30.5 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'كرارة',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture7,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'كريما نوفا',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture8,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'كابتشينو',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture10,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'لاينز لثر فينش',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture12,
    Size: '45.7 cm x 45.7 cm ، 81 cm x 40 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm, 2 cm',
    Type: 'رخام', 
},
{
    Name:'كوفي رويال',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture14,
    Size: '60 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'ترافرتينو لايت',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture15,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'كريستال بيج',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture16,
    Size: '60 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'جولدن كريم',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture18,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'شفاكتشي',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: SquarePicture19,
    Size: '60 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'جرانيت', 
},
{
    Name:'كوفي رويال',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture21,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'برلاتو',
    Origin:'إيطاليا',
    CountryFlag: ItalyFlag,
    Image: SquarePicture22,
    Size: '30 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'جلاكسي',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: SquarePicture23,
    Size: '30 cm x 61 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'جرانيت', 
},
{
    Name:'داينو رويال',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture25,
    Size: '80 cm x 80 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'شفاكتشي',
    Origin:'الهند',
    CountryFlag: IndiaFlag,
    Image: SquarePicture26,
    Size: '30.5 cm x 30.5 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'جرانيت', 
},
{
    Name:'نيو جري',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture27,
    Size: '60 cm x 60 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'كوبرا جري',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture28,
    Size: '61 cm x 61 cm',
    Cut: 'ترابيع',
    Thickness: '2 cm',
    Type: 'رخام', 
},
{
    Name:'نوتشي ترافرتينو تركيبة فرنسية',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture85,
    Size: 'مقاسات متعددة',
    Cut: 'ترابيع',
    Thickness: '1.5 cm',
    Type: 'رخام', 
},
{
    Name:'نوتشي ترافرتينو',
    Origin:'تركيا',
    CountryFlag: TurkeyFlag,
    Image: SquarePicture89,
    Size: 'مقاسات متعددة',
    Cut: 'ترابيع',
    Thickness: '1.5 cm',
    Type: 'رخام', 
},
]

export default SquaresList;