import React from 'react';
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbreact';
import "./ProductsCard.css";

const ProductsCard = ({title,body,image,onClick, ButtonValue}) => {
  return (
    <MDBCol className="tc">
      <MDBCard className='cardStyler'>
        <MDBCardImage className="img-fluid" src={image} waves />
        <MDBCardBody>
          <MDBCardTitle className='font-weight-bold'>{title}</MDBCardTitle>
          <MDBCardText className='cardTextFontSize'>
                {body}
          </MDBCardText>
            <button className="btn" type="button" name="button" onClick = {onClick} value={ButtonValue} >إضغط لإظهار المنتجات المتاحة</button>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  )
}

export default ProductsCard;