import React from 'react';
import Iframe from 'react-iframe';
import {MDBView} from "mdbreact";

const ContactsComponent = ({ mainHeader, location, buildingPicture, phone, mapLocation }) => {
    return (
        <div>
            <div id="firstlocation">
                <h3 style={{ textAlign: 'right', marginRight: '5rem', fontWeight:'bold' }}>{mainHeader}</h3>
                <hr />
            </div>
            <div style={mapitemstyler}>
                <div style={{ marginRight: '6%',maxWidth:'100vw',overflow:'hidden' }}>
                    <Iframe url={mapLocation}
                        width="800px"
                        height="600px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative" />
                </div>
                <div>
                    <MDBView waves>
                        <img alt="Company Building" src={buildingPicture} className="rounded z-depth-2 img-fluid" />
                    </MDBView>
                    <div style={{ margin: '10% 5%', textAlign: 'right', maxWidth: '450px' }}>
                        <h5 style={headerstyler}>الموقع</h5>
                        <p style={paragraphstyler}>{location}</p>
                        <h5 style={headerstyler}>رقم هاتف المقر</h5>
                        <p style={paragraphstyler}>{phone}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactsComponent;

const mapitemstyler = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap-reverse',
    marginBottom: '200px',
}

const headerstyler = {
    fontWeight:'bold',
    fontSize:'1.75rem'
}

const paragraphstyler = {
    fontSize:'1.4rem'
}