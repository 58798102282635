import React from 'react';
import NavbarPage from "../../components/navbar";
import Banner from "../../components/Banner";
import Footer from "../../components/footer";
import NewsComponent from './NewsComponent';
import News1 from "./news1.jpg";
import News2 from "./news2.jpg";
import News3 from "./news3.jpg";

const news = [
  {
    title:'وصول حاوية جرانيت',
    date:'2019-06-16',
    body:'وصلت اليوم حاوية جرانيت بها أصناف (جرانيت بلو أفغاني 3سم - جرانيت ميرون كريستال هندي 3سم) وتستخدم هذه الأصناف في دواليب المطابخ والدرج',
    image: News1,
  },
  {
    title:'كن مميز باختيارك',
    date:'2019-07-03',
    body:'الكابتشينو التركي بلونه البيج الممزوج بدرجات اللون البني الطبيعي يمنحك لون ومزاج القهوة، مقاس (60×30) وبسعر 95 دينار، تستعمل في تركيب أرضيات الممرات والصالات وأيضا تلبيس الحوائط والأرضيات في الحمامات لأصحاب الذوق الرفيع ممن يعشقون الألوان الطبيعية وكيفية تركيبها ومزجها مع بعضها',
    image: News2,
  },
  {
    title:'تخفيضات السرايا للرخام مستمرة',
    date:'2019-07-10',
    body:'نوفر لزبائننا الكرام كل ماهو مميز, أصناف جرانيت برازيلي وهندي للمطابخ وبأسعار مخفضة تبدأ من 80 دينار إلى 135 دينار، نقبل الدفع النقدي أو بالصك المصدق',
    image: News3,
},
]

const News = () => {
  return (
    <div>
    <NavbarPage 
      home = {false}
      aboutus = {false}
      products = {false}
      news = {true}
      contacts = {false}
    />
    <Banner body = "أخبار الشركة" />
    {
      news.map((item,i) => {
        return (
          <NewsComponent 
          title={news[news.length -1 - i].title} 
          date={news[news.length -1 - i].date}
          body={news[news.length -1 - i].body} 
          image = {news[news.length -1 - i].image} 
          />
        )
      })
    } 
    <Footer />
    </div>  
  )
}

export default News;