import React from "react";
import NavbarPage from "../../components/navbar";
import Banner from "../../components/Banner";
import Footer from "../../components/footer";
import ParagraphWithPicture from "../../components/ParagraphWithPicture";
import companyVision from "./companyVision.jpg";
import companyGoals from "./companyGoals.jpg";
import MultipleParagrapshwithPicture from "../../components/MultipleParagraphswithPicture";

const aboutus = [
  {
    title: "رؤية الشركة",
    body:"أن تكون شركة السرايا الحمراء من أفضل شركات الرخام التي تقدم لعملائها حلول تسويقية متطورة وعملية قابلة للتطبيق وأن تكون الإختيار الأول للمستثمرين الذين يبحثون عن الجودة والأمانة وأن تكون خدماتنا دائما فوق مستوى توقعات عملائنا وأن نستعين لتحقيق ذلك بأفضل الكفاءات المهنية. الأمر الذي سيجعل شركتنا من الشركات الرائدة في السوق الليبي في مجال قص وتركيب الرخام والجرانيت",
    image: companyVision,
  }
];

const aboutusgoals = {
  title: "أهداف الشركة",
  body: [
    "تركز شركة السرايا الحمراء على التحسين والتطوير المستمر للمنتجات التي تقدمها وفقا لإحتياجات وتوقعات عملائها وتسعى لمعرفة آرائهم وتسهيل طرق التواصل معهم للوصول إلى الأجمل ولأفضل",
    "أيضا تركز الشركة على الدقة في العمل والصناعة والتقيد والإلتزام بمواعيد التسليم لعملائها",
    "توفير بضاعة للبيع بالقطاعي والجملة وتتيح إمكانية استيراد البضاعة في كل الأوقات بالنسبة للكميات الكبيرة حسب رغبة وطلب الزبون وبأسعار معقولة ومنافسة للسوق"
  ],
  image: companyGoals
};

const AboutUs = () => {
  return (
    <div>
      <NavbarPage
        home={false}
        aboutus={true}
        products={false}
        news={false}
        contacts={false}
      />
      <Banner body="عن الشركة" />

      {aboutus.map((item, i) => {
        return (
          <ParagraphWithPicture
            title={aboutus[i].title}
            body={aboutus[i].body}
            image={aboutus[i].image}
          />
        );
      })}

      <MultipleParagrapshwithPicture
        title={aboutusgoals.title}
        body1={aboutusgoals.body[0]}
        body2={aboutusgoals.body[1]}
        body3={aboutusgoals.body[2]}
        image={aboutusgoals.image}
      />

      <Footer />
    </div>
  );
};

export default AboutUs;
